<template>
    <div class="h-100 w-100 flex-in-middle dark" v-show="page_ready" :style="{backgroundImage:'url('+bg_image+')'}">
        <div class="login-wrap">
            <div class="left-part">
                <div class="title">{{ $t("TITLE.WELCOME") }}</div>
                <div class="line"></div>
                <div class="text">{{ sign_in_message }}
                </div>
            </div>
            <div class="right-part">
                <el-tabs v-model="tab" stretch v-el-tabs-large-header>
                    <el-tab-pane :label="$t('TITLE.NETID')" name="local" class="position-relative tab-pane active">
                        <div class="">
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.id" clearable placeholder="Your ID"
                                          v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.password" show-password type="password" clearable
                                          placeholder="Password" v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 43px;">
                                <el-button type="dark" round class="w-100" @click="submitForm" style="height: 45px;">
                                    {{ $t("TITLE.SIGN-IN") }}
                                </el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="language">
                <div class="mr-2 locale" @click="setLocale">{{ english ? "English" : "中文" }}</div>
            </div>
            <div style="position:fixed;bottom: 0px;left: 0;right: 0;text-align: center">
                <page-footer></page-footer>
            </div>
        </div>
    </div>
</template>

<script>

import SignIn from "@/views/mixins/signin-mixin"
import "@/common/directives/el-tabs-large-header"
import "@/common/directives/el-round-input"
import PageFooter from "@/components/page-footer";
import VueCookie from 'vuecookie'
import qywxCommon from "@/common/mixins/qywxCommon";

export default {
    components: {PageFooter},
    mixins: [SignIn,qywxCommon],
    data() {
        return {
            tab: 'local',
        }
    },
    mounted() {
        if (this.$isWechat()) {
            if (!this.jumping) this.qywxHandler();
        }
    },
    created() {
        const vm = this;
        if (this.$route.query.schoolid === '74942') { //大讲堂跳转
            this.loading = true;
            this.$api('User.SufeDjtLogin',this.$route.query,1).then((res)=>{
                VueCookie.set('SUFEDJTLOGIN',1,{expires:'1D'});
                const ret = res.data.data;
                if (ret.user) { // 已经登录了，则进入
                    //vm.$messageBox.error('Has User');
                    vm.setUserInfo(ret.user);
                    vm.$router.replace("/");
                } else {// 如果都不行，则进行网络登录
                    this.loading = false;
                    vm.$messageBox.error(ret.message);
                    vm.$router.replace("/");
                }
            });
        }
    },
}
</script>

<style lang="scss">
@import "../scss/signin-dark.scss";

</style>
